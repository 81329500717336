.email-container {
    tr td {
        position: relative;
    }

    .email-header {
        border: 0 !important;
    }

    h3 {
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-weight: 700;
    }

    .email-photo-credits {
        font-size: .75rem;
    }

    .photo-credits {
        font-size: .75rem;
        text-align: left;

        table {
            margin: 0 auto;
        }
    }

    .cta-row {
        td {
            text-align: center;
        }
    }
}

#email1 {
    .email-header {
        min-height: 200px;
        position: relative;
        overflow: hidden;
        width: 100%;

        img {
            position: absolute;
            width: 100%;
            z-index: 0;
        }

        h3 {
            font-size: 42px;
            margin: 0;
            padding: 12px 12px 12px 24px;
            position: relative;
            z-index: 1;
        }
    }

    .block2 {
        vertical-align: top;

        h3 {
            font-size: 42px;
            padding: 0;
            margin: -10px 0 12px 0;
        }

        p {
            margin-bottom: 0
        }
    }

    .block3 {

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 0;
        }

        h3 {
            color: #000;
            font-size: 42px;
            text-align: center;
            margin: 0;
            padding: 0;
            position: absolute;
            top: 24px;
            left: 0;
            width: 100%;
            z-index: 10;
        }
    }
}

#email2 {
    thead {
        h3 {
            color: #FFFFFF;
            text-align: center;
            width: 100%;
        }
    }
}

#email5 {
    .bkgd-img {
        height: 182px;
        position: relative;
        vertical-align: bottom;

        img {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
        }

        p {
            position: relative;
            z-index: 10;
        }
    }
}

#email6 {
    .order-table {
        border: 1px solid #CCC;

        thead tr td,
        tbody tr td {
            padding: 3px 0;

            &:first-child {
                padding-left: 3px;
            }
        }

        tbody {
            tr {
                &:nth-child(odd) {
                    background-color: #F3F3F3;
                }
            }
        }

        tfoot tr {
            border-top: 1px solid #CCC;
        }
    }
}