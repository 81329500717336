.email-wrap-container {
    margin: 0 auto;
    width: 640px;

    .email-header {
        border: 1px solid #333;

        div {
            padding: .5rem;
        }

        a.unsubscribe {
            font-size: 0.75rem;
        }
    }
}